<template>
  <div class="panel-container">
    <div class="poster-container">
      <!-- <img class="poster-logo" src="@/assets/login/logo.png" alt="logo"> -->
      <!-- <div class="poster-title">你还没账户吗？</div> -->
      <!-- <div class="poster-content">注册加入我们</div> -->
      <!-- <div class="poster-buttons">
        <router-link class="poster-button" replace to="signup">
          <span>免费注册</span>
          <img src="../../assets/login/right.png" alt="right" />
        </router-link>
      </div> -->
    </div>
    <div class="content-container">
      <div class="tabs">
        <div
          v-for="option in tabOptions"
          :key="option.value"
          :class="['tab', { 'is-active': tabValue === option.value }]"
          @click="tabValue = option.value"
        >
          <span>{{ option.label }}</span>
        </div>
      </div>
      <el-form
        key="password"
        v-if="tabValue === 'password'"
        ref="passwordFrom"
        :model="passwordFrom"
        :rules="passwordRules"
        class="content-form"
      >
        <el-form-item prop="loginName">
          <el-input
            size="large"
            v-model="passwordFrom.loginName"
            placeholder="请输入用户名或手机号码"
            clearable
          >
            <img class="icon1" slot="prepend" src="@/assets/login/icon5.png" />
          </el-input>
        </el-form-item>
        <el-form-item prop="loginPwd">
          <el-input
            size="large"
            type="password"
            show-password
            v-model="passwordFrom.loginPwd"
            placeholder="请输入8~20位密码"
            @keyup.enter.native="submit('passwordFrom')"
            minlength="8"
            maxlength="20"
            clearable
          >
            <img class="icon1" slot="prepend" src="@/assets/login/icon3.png" />
          </el-input>
        </el-form-item>
        <el-button
          class="content-form-submit"
          size="large"
          type="primary"
          @click="submit('passwordFrom')"
          >登录
        </el-button>
      </el-form>

      <!-- <el-form
        key="sms"
        v-else-if="tabValue === 'sms'"
        ref="smsFrom"
        :model="smsFrom"
        :rules="smsRules"
        class="content-form"
      >
        <el-form-item prop="loginName">
          <el-input
            size="large"
            v-model="smsFrom.loginName"
            placeholder="请输入手机号码"
            maxlength="11"
            clearable
          >
            <img class="icon1" slot="prepend" src="@/assets/login/icon1.png" />
          </el-input>
        </el-form-item>
        <el-form-item prop="verifyCode">
          <el-input
            size="large"
            placeholder="请输入验证码"
            v-model="smsFrom.verifyCode"
            @keyup.enter.native="smsFromValidateAndSubmit"
            maxlength="6"
            clearable
          >
            <img class="icon1" slot="prepend" src="@/assets/login/icon2.png" />
            <el-button
              type="primary"
              :disabled="!!timer"
              slot="append"
              @click="sendCode(smsFrom.loginName)"
            >
              <span v-if="timer">{{ time }}s</span>
              <span v-else>获取验证码</span>
            </el-button>
          </el-input>
        </el-form-item>

        <el-button
          class="content-form-submit"
          size="large"
          type="primary"
          @click="smsFromValidateAndSubmit"
          >登录</el-button
        >
      </el-form> -->

      <!-- <div class="a-links">
        <router-link class="a-link" replace to="forgot">忘记密码？</router-link>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import md5 from 'js-md5'
export default {
  data() {
    return {
      tabValue: 'password',
      tabOptions: [
        {
          label: '密码登录',
          value: 'password'
        }
        // {
        //   label: '验证码登录',
        //   value: 'sms'
        // }
      ],

      passwordFrom: {
        deviceType: 'pc',
        loginName: '',
        loginPwd: ''
      },
      passwordRules: {
        loginName: [
          { type: 'string', required: true, message: '请输入登录名或手机号码' },
          {
            type: 'string',
            pattern: /^.{4,20}$/,
            min: 4,
            max: 20,
            message: '登录名或手机号码错误'
          }
        ],
        // loginPwd: [{ type: 'string', required: true, message: '请输入密码' }],
        loginPwd: [
          { type: 'string', required: true, message: '请输入登录密码' },
          { type: 'string', min: 8, max: 20, message: '密码长度为8-20位' },
          {
            type: 'string',
            pattern: /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,20}$/,
            message: '密码至少包含数字/字母/字符2种组合'
          }
        ]
      }

      // smsFrom: {
      //   deviceType: 'pc',
      //   loginName: '',
      //   verifyCode: ''
      // },
      // smsRules: {
      //   loginName: [
      //     { type: 'string', required: true, message: '请输入手机号码' },
      //     {
      //       type: 'string',
      //       pattern: /^1[3456789]\d{9}$/,
      //       message: '手机号码不正确'
      //     }
      //   ],
      //   verifyCode: [
      //     { type: 'string', required: true, message: '验证码不能为空' }
      //   ]
      // },

      // timer: null,
      // time: 60
    }
  },
  computed: {
    ...mapState(['userInfo'])
    // redirect() {
    //   return this.$route.query.redirect || '/'
    // }
  },
  created() {
    // if (this.userInfo) this.$service('user/logout')
    // this.setUserInfo(null)
  },
  methods: {
    // ...mapMutations(['setUserInfo', 'setBtnAuth']),

    // 发送验证码
    // sendCode(mobilePhone, smsType = 1) {
    //   if (this.timer) return
    //   if (mobilePhone === '') return this.$message.error('请输入手机号码')
    //   if (!/^1[3456789]\d{9}$/.test(mobilePhone)) {
    //     return this.$message.error('请输入正确手机号码')
    //   }

    //   clearInterval(this.timer)
    //   this.timer = setInterval(() => {
    //     if (this.time === 0) {
    //       clearInterval(this.timer)
    //       this.timer = null
    //       this.time = 60
    //     } else {
    //       this.time = --this.time
    //     }
    //   }, 1000)

    //   return this.$service('sms/sendVerifyCode', { mobilePhone, smsType }).then(
    //     (result) => {
    //       return this.$message.success('请注意查收手机短信')
    //     }
    //   )
    // },

    // 密码登录 校验并提交
    // passwordFromValidateAndSubmit() {
    //   const { passwordFrom } = this.$refs
    //   if (!passwordFrom) return
    //   return passwordFrom.validate((valid) => {
    //     if (!valid) return
    //     return this.passwordFromSubmit()
    //   })
    // },
    // 密码登录 提交
    // passwordFromSubmit() {
    //   if (this._load) return
    //   this._load = true
    //   return this.$service('user/login', {
    //     ...this.passwordFrom,
    //     loginPwd: md5(this.passwordFrom.loginPwd)
    //   })
    //     .then(this.loginSuccess)
    //     .then(
    //       () => {
    //         this._load = false
    //       },
    //       () => {
    //         this._load = false
    //       }
    //     )
    // },

    submit(passwordFrom) {
      this.$refs[passwordFrom].validate(async(valid) => {
        if (valid) {
        // const res = await this.validCaptcha()
        // console.log(res)
        // if (res) {
          this.login()
        // }
        }
      })
    },
    async login() {
      const params = { ...this.passwordFrom }
      params.loginPwd = md5(params.loginPwd)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$store.dispatch('login', params)
        .then(() => {
          this.$message.success('登录成功！')
          loading.close()
          this.$router.push({ path: '/home' })
        })
        .catch((error) => {
          this.$message.error(error || '登录失败')
          loading.close()
        })
    }

    // 短信登录 校验并提交
    // smsFromValidateAndSubmit() {
    //   const { smsFrom } = this.$refs
    //   if (!smsFrom) return
    //   return smsFrom.validate((valid) => {
    //     if (!valid) return
    //     return this.smsFromSubmit()
    //   })
    // },

    // 短信登录 提交
    // smsFromSubmit() {
    //   if (this._load) return
    //   this._load = true
    //   return this.$service('user/login', this.smsFrom)
    //     .then(this.loginSuccess)
    //     .then(
    //       () => {
    //         this._load = false
    //       },
    //       () => {
    //         this._load = false
    //       }
    //     )
    // },

    // 登录成功
    // loginSuccess(userInfo) {
    //   return this.$service('/user/role/getButtonPowerList', {}, (btnList) => {
    //     const btnAuth = {}
    //     for (const item of btnList) {
    //       btnAuth[item.perms] = true
    //     }
    //     this.setBtnAuth(btnAuth)
    //     this.setUserInfo(userInfo)
    //     this.$message.success('登录成功')
    //     this.$router.replace(this.redirect)
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
$--color-primary: #3E6DF5;
@import 'element-ui/packages/theme-chalk/src/common/var';
@import 'element-ui/packages/theme-chalk/src/mixins/mixins.scss';
@import 'element-ui/packages/theme-chalk/src/mixins/_button.scss';

// 容器
.panel-container {
  display: flex;
  border-radius: 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  width: 1000px;
  height: 480px;
  position: relative;
  left: 50%;
  top: 39%;
  transform: translate(-50%,-50%);
  .poster-container,
  .content-container {
    &:first-child {
      border-radius: 16px 0 0 16px;
    }
    &:last-child {
      border-radius: 0 16px 16px 0;
    }
  }
  .poster-container {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .content-container {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

// 侧边广告
.poster-container {
  width: 480px;
  background: url(../../assets/login/login-bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  padding: 28px;
  .poster-logo {
    display: block;
    max-width: 100%;
    max-height: 46px;
  }
  .poster-title {
    margin-top: 63px;
    margin-bottom: 20px;
    font-size: 40px;
    color: #fff;
  }
  .poster-content {
    font-size: 24px;
    color: #fff;
  }
  .poster-buttons {
    margin-top: 195px;
    -webkit-tap-highlight-color: transparent;
  }
  .poster-button {
    text-decoration: none;
    display: inline-block;
    width: 160px;
    height: 48px;
    border-radius: 4px;
    border: solid 2px #d4d8ea;
    line-height: 48px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
      transform: scale(0.95);
    }
    img {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0 10px;
      margin-top: -3px;
      vertical-align: middle;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

// 登录容器
.content-container {
  width: 100%;
  background-color: #fff;
}

.tabs .tab.is-active {
  color: #ffffff;
  background: linear-gradient(
    360deg,
    rgba(62, 109, 245, 1) 0%,
    rgba(114, 166, 251, 1) 100%
  );
}
.el-input-group__prepend {
  height: 40px;
}

//登录容器图标
.icon1 {
  width: 20px;
  height: 20px;
}

// tab标签项
.tabs {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 30px;
  border-bottom: 1px solid #e1e7f2;
  box-sizing: border-box;
  .tab {
    display: inline-block;
    font-size: 24px;
    height: 65px;
    line-height: 65px;
    padding: 0 30px;
    color: #666666;
    background-color: #f7f9fc;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    margin-top: 0 - (65px - 45px + 1px);
    border-radius: 16px 16px 0 0;
    transition: all 0.2s;
    &.is-active {
      color: #fff;
      background-color: #3E6DF5;
    }
  }
}

// 表单
.content-form {
  padding: 80px 70px 0;

  // 修复按钮
  .el-input {
    &::v-deep {
      .el-button {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        @include button-size(12px, 20px, 14px, 4px);
        padding-bottom: 13px;
        margin-left: -21px;
        margin-right: -21px;
        @include m(primary) {
          @include button-variant(
            $--button-primary-font-color,
            $--button-primary-background-color,
            $--button-primary-border-color
          );
        }
      }
    }
  }

  // 添加 el-input large 尺寸
  .el-input.el-input--large {
    font-size: 16px;
    &::v-deep {
      .el-input__inner {
        height: 48px;
        line-height: 48px;
      }
      .el-input__icon {
        line-height: 48px;
      }
      .el-button {
        @include button-size(16px, 22px, 16px, 4px);
        padding-bottom: 15px;
      }
    }
  }

  // 添加 el-button large 尺寸
  .el-button.el-button--large {
    @include button-size(18px, 24px, 20px, 4px);
  }

  .content-form-submit {
    display: block;
    width: 100%;
    margin-top: 50px;
  }
}

.content-form .el-button.el-button--large {
  border-radius: 32px;
}

.a-links {
  text-align: center;
  margin-top: 20px;
  .a-link {
    text-decoration: none;
    display: inline-block;
    color: #3E6DF5;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: mix(#0091ff, #ffffff, 80%);
    }
  }
}
</style>
